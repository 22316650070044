import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { selectCurrentPhonenumbers } from "../data/devicesSlice";
import { currentPageSet, selectCurrentPage } from "../data/uiSlice";

import SubPageData from "./SubPageData";
import SubPageSettings from "./SubPageSettings";
import SubPageDeviceList from "./SubPageDeviceList";

import {
  Box,
  styled,
  ToggleButton as ToggleButtonMui,
  ToggleButtonGroup as ToggleButtonGroupMui
} from "@mui/material/";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import SettingsIcon from "@mui/icons-material/Settings";


// Subpage select button styles
const ToggleButtonGroup = styled(ToggleButtonGroupMui)({
  '& .MuiToggleButtonGroup-grouped': {
    borderTop: 0,
    borderRadius: "0 0 4px 4px",
  },
})

const ToggleButton = styled(ToggleButtonMui)(({theme}) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    //borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'inherit'
  }
}))

const buttonIconProps = {
  color: "gray",
  sx: {mr: 1},
}

const subPage = {
  "DEVICE-LIST": SubPageDeviceList,
  "DATA": SubPageData,
  "MAIN": SubPageSettings,
}

// Devices page: contains buttons for selecting device subpages and the selected subpage.
const SubPageDevices = withTranslation()(({t}) => {
  const dispatch = useDispatch();
  const phonenumbers = useSelector(selectCurrentPhonenumbers) || [];
  const currentDeviceIsInUse = phonenumbers?.some(num => num.userType === "superadmin" && !!num.telegramId );
  const currentSubPage = useSelector(selectCurrentPage);

  const handleSubPageChange = (_, newValue) => {
    if(newValue != null){
      dispatch(currentPageSet(newValue));
    }
  };

  function SelectedSubpage() {
    if(!currentDeviceIsInUse){
      return <SubPageDeviceList />
    }
    const CurrentSubPage = subPage[currentSubPage] ?? SubPageDeviceList
    return <CurrentSubPage />;
  }

  return (
    <Box sx={{mt:-3}}>
      <ToggleButtonGroup
        value={currentSubPage}
        exclusive
        size="small"
        onChange={handleSubPageChange}
        aria-label="device subpage selection buttons"
      >
        <ToggleButton 
          value="DEVICE-LIST" 
          aria-label="device list tab"
          >
          <ListAltRoundedIcon {...buttonIconProps} />
          {t('device-list-tab')}
        </ToggleButton>
        <ToggleButton 
          value="DATA" 
          disabled={!currentDeviceIsInUse}
          aria-label="device data tab"
          >
          <InsertChartOutlinedIcon {...buttonIconProps} />
          {t('data-tab')}
        </ToggleButton>
        <ToggleButton 
          value="MAIN" 
          disabled={!currentDeviceIsInUse}
          aria-label="device settings tab"
          >
          <SettingsIcon {...buttonIconProps} />
          {t('settings-tab')}
        </ToggleButton>
      </ToggleButtonGroup>
      <SelectedSubpage />
    </Box>
  )
})
export default SubPageDevices