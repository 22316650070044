import { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from "react-query";

//import { checkMode } from '../theme.js';
import logger from '../../utility/logger.mjs';
import { 
  selectAllDevices,
  selectDeviceById,
  selectDeviceListData,
} from '../../data/devicesSlice.js';
import { 
  selectCurrentDeviceId, 
  currentDeviceSet, 
  currentPageSet,
  selectLoginType,
  timeRangeSet,
  selectLanguage,
  brandSet,
  themeModeSet,
  selectThemeMode,
} from '../../data/uiSlice.js';
import MapDialog from '../../components/MapDialog.js';
import {DeviceStatus} from "./DeviceStatus";
import DeviceDetails from "./DeviceDetails.js";
import { 
  fetchOnomondoData, 
//  fetchActivationDates 
} from "../../server/serverOperation.js";
import DeviceTypeIcon from '../Icons/DeviceTypeIcon.js';
import DeviceTableNameColumnDef from './DeviceTableNameColumnDef.js';
import DeviceStatusBadge from './DeviceStatusBadge.js';
import DeviceActionButtons from './DeviceActionButtons.js';
import useDataGridLocaleText from '../Locations/useDataGridLocaleText.js';
import DeviceStatusIcon from '../Icons/DeviceStatusIcon.js';

import { 
  DataGridPro, 
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridToolbar,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Radio,
  Stack,
  CircularProgress,
  IconButton,
  ListItemButton,
  Tooltip, 
} from '@mui/material/';



const PureDeviceTable = ({t, showDevicesNotInUse}) => {
  const loginType = useSelector(selectLoginType);
  const devices = useSelector(selectAllDevices).filter( device => showDevicesNotInUse ? !device.inUse : device.inUse);
  const onmondoUUIDs = devices.filter( dev => dev.onomondoOK ).map( dev => dev.uuid );
  // const currentDeviceId = useSelector(selectCurrentDeviceId);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
  // const [selectedDevice, setSelectedDevice] = useState(currentDeviceId);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);


  const getDetailPanelContent = useCallback(
    ({id}) => <DeviceDetails deviceId={id} hideActionButtons/>,
    [],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);
  
  //const UUIDsWithoutActivationDate = devices.filter( dev => dev.activationDate === undefined ).map( dev => dev.uuid ); 
  // const { data:onomondoData, refetch:refetchOnomondoData, status:onomondoDataStatus, isSuccess:onomondoDataIsSuccess } = useQuery(
  //   ["onomondoData"],
  //   () => {
  //     return fetchOnomondoData(onmondoUUIDs);
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  // const deviceTableRows = devices.map( dev => {
  //   const uuid = dev.uuid;
  //   // const i = activationDates?.findIndex( d => d.uuid === uuid)
  //   // const activationDate = activationDates?.[i]?.activationDate.slice(0,10);
  //   // const lastSeen = activationDates?.[i]?.lastSeen;
  //   const activationDate = dev.activationTimes;
  //   const devData = { 
  //     ...dev, 
  //     ...onomondoData?.[uuid], 
  //   }
  //   return <DeviceTableRow dev={devData} currentId={currentId} selectSubPage={selectSubPage} onomondoKnown={onomondoDataIsSuccess} />   
  // })

  // const deviceNotInUseTableRows = deviceNotInUseListData.map( dev => <DeviceTableRow dev={dev} currentId={currentId} deactivateNameLink selectSubPage={selectSubPage} onomondoKnown={onomondoDataIsSuccess} /> )
  
  // if(loginType !== "telegram"){
  //   return <>Log in with Telegram in order to see the diveces linked to your phone number.</>;
  // }

  const columns = [
    {
      field: 'type',
      headerName: '',
      width: 40,
      display: 'flex',
      align: 'center',
      // render device type as icons
      renderCell: row => <DeviceTypeIcon type={row.value} />,
    },
    {
      field: 'name',
      headerName: t('device'),
      flex: 1,
      renderCell: DeviceTableNameColumnDef
    },
    {
      field: 'deviceStatus',
      headerName: t('status'),
      width: 65,
      display: 'flex',
      align: "center",
      renderCell: ({row}) => <DeviceStatus deviceId={row?.id} />,
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      getActions: useCallback(({row}) => DeviceActionButtons({deviceId: row.id, variant: (showDevicesNotInUse ?  "activateDevice" : "array") }))
    },
    {
      field: 'uuid',
    },
  ];

  return (
    <DataGridPro
        localeText={useDataGridLocaleText()}
        density='compact'
        columns={columns}
        rows={devices} 
        disableColumnMenu
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ 
          toolbar: GridToolbar, 
          }}
        initialState={{
          columns: {
            // Hide columns that are only needed for sorting
            // and some columns if it is a table with not active devices 
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
              'uuid': false,
              'type': false,
            }
          },
          // apply quick filtering (search bar) to hidden columns
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: false,
            },
          },  
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          }
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        hideFooter
    />
  )
}
const DeviceTable = withTranslation()(PureDeviceTable);

export default DeviceTable;