import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { withTranslation } from "react-i18next";

import useScreenSize from "../../utility/useScreenSize.js";
import { selectCurrentProjectLocations } from "../../data/locationsSlice.js";
import { selectCurrentProjectDevices } from "../../data/devicesSlice.js";
import { selectCurrentPage, selectCurrentProjectId } from "../../data/uiSlice.js";
import { getDeviceAlerts } from "../../server/serverOperation.js";
import { alarmInit } from "../../data/alarmsSlice.js";
import { defaultRowBackgroundColor } from "./locationTableSettings.js";

import LocationDetails from "./LocationDetails.js";
import DeviceDetails from "../Devices/DeviceDetails.js";
import { LocationAlarms } from "../Alarms/LocationAlarms.js";
import { DeviceAlarms } from "../Alarms/DeviceAlarms.js";
import LocationDeleteButton from "./LocationDeleteButton.js";
import LocationTableGroupingColumnDef from "./LocationTableGroupingColumnDef.js";
import LocationTableToolBar from "./LocationTableToolbar.js";
import ExpandAllToggle from './LocationTableExpandAllTogle';
import LocationAddDialog from "./LocationAddDialog.js";

import { FREE_DEVICES_LOCATION_ID } from "./locationTableSettings.js";
import useDataGridLocaleText from "./useDataGridLocaleText.js";

import {
  DataGridPro,
  gridClasses,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import {
  Stack,
} from '@mui/material/';
import LocationMoveDialog from "./LocationMoveDialog";

// Table styling.
const dataGridStyles = {
  padding: 1,
  paddingBottom: 2,
  mx: 0,
  // disable cell focus 
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  '& div div div div >.MuiDataGrid-cell': {
    borderTop: 0,
  },
  // ** Row styling ** 
  // Device rows
  [`.${gridClasses.cell}.deviceRow-inner`]: {
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderColor: (theme) => theme.palette.success.main,
  },
  [`.${gridClasses.cell}.deviceRow-rightmost`]: {
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    borderColor: (theme) => theme.palette.main,
    borderRadius: "0 10px 10px 0",
  },
  [`.${gridClasses.cell}.deviceRow-leftmost`]: {
    cursor: 'pointer',
    // styling is handled in LocationTableGroupingColumnDef.js
  },
  // Location rows
  [`.${gridClasses.cell}.locationRow-inner`]: {
    backgroundColor: (theme) => defaultRowBackgroundColor(theme),
  },
  [`.${gridClasses.cell}.locationRow-rightmost`]: {
    // paddingRight: 1,
    borderRadius: "0 10px 10px 0",
    backgroundColor: (theme) => defaultRowBackgroundColor(theme),
  },
  [`.${gridClasses.cell}.locationRow-leftmost`]: {
    cursor: 'pointer',
    // Styling of these cells are handled in LocationTableGroupingColumnDef.js
  },
}

const PureLocationTable = ({ t }) => {
  const { windowIsNarrow } = useScreenSize();
  const dispatch = useDispatch();
  const currentPage = useSelector(selectCurrentPage);
  const currentProjectId = useSelector(selectCurrentProjectId);
  const locations = useSelector(selectCurrentProjectLocations);
  // Show only devices associated to selected project and those that are in use.
  const rawDevices = useSelector(selectCurrentProjectDevices).filter(device => device.inUse);
  // Equip devices with location paths, unique ids, and isDeviceRow flags
  const devices = rawDevices.map(device => {
    const parentLocationPath = locations.find(location => location.devices.includes(device.id))?.path ?? ['free-devices'];
    const path = parentLocationPath.concat([device.id]);
    return {
      ...device,
      id: `d${device.id}`,
      deviceId: device.id,
      path,
      isDeviceRow: true
    }
  })

  const apiRef = useGridApiRef();

  // Refetch device alert and close all the detail panels 
  // if location page is opened or a new project is selected
  const deviceIds = rawDevices.map(device => device.id);
  const fetchAlertsEnabled = deviceIds != null && deviceIds.length > 0;
  const { refetch: refetchAlerts, isLoaging: getAlertsIsLoading } = useQuery(
    ["deviceAlerts", deviceIds],
    async () => {
      const alerts = await getDeviceAlerts({ deviceIds });
      dispatch(alarmInit(alerts));
    },
    {
      enabled: fetchAlertsEnabled,
    }
  );

  useEffect(() => {
    if (fetchAlertsEnabled) {
      refetchAlerts();
      apiRef.current.setExpandedDetailPanels([]);
    }
  }, [apiRef, refetchAlerts, fetchAlertsEnabled, currentPage, currentProjectId]);

  // Detail panel stuff
  const getDetailPanelContent = useCallback(
    (params) => {
      // is device row
      if (params.row?.isDeviceRow) {
        return <DeviceDetails deviceId={params.row.deviceId} isInLocationTable tableApiRef={apiRef} />
      }
      // is location row
      return <LocationDetails locationId={params.id} />
    },
    [],
  );
  const getDetailPanelHeight = useCallback(() => 'auto', []);

  // TABLE ROWS
  const freeDevicesMockLocation = [{
    id: FREE_DEVICES_LOCATION_ID,
    name: 'free-devices',
    parentId: null,
    path: ['free-devices'],
  }];
  const tableRows = devices.concat(locations).concat(freeDevicesMockLocation);

  // TABLE COLUMNS AND THEIR HEADERS
  // The grouping column, i.e. the leftmost column
  const groupingColDef = useMemo(
    () => ({
      flex: 2,
      headerName: <Stack direction="row" spacing={1} alignItems="center" ><ExpandAllToggle /><div>{t('location')}</div></Stack>,
      renderCell: (params) => LocationTableGroupingColumnDef({ ...params, apiRef })
    }), [locations]);

  const renderAlarmCell = params => {
    // Device rows
    if (params.row?.isDeviceRow) {
      return (
        <DeviceAlarms
          id={params.row.deviceId}
        />
      );
    }
    // Location rows
    return (
      <LocationAlarms
        locationId={params.id}
        tableApiRef={apiRef}
        node={params}
      />
    );
  }

  // Other column definitions. Ordering of the array defines the ordering of columns
  const columns = useMemo(
    () => [
      {
        field: 'alarms',
        headerName: t('alarms'),
        flex: windowIsNarrow ? 2 : 1,
        colSpan: (_, row) => row.isDeviceRow ? 2 : 1,
        renderCell: renderAlarmCell,
      },
      {
        field: 'actions',
        type: 'actions',
        width: windowIsNarrow ? 30 : 110,
        minWidth: 30,
        getActions: (params) => {
          if (params.id !== FREE_DEVICES_LOCATION_ID) {
            return ([
              <LocationAddDialog
                locationId={params.id}
                showInMenu={windowIsNarrow}
                dataGridAction
              />,
              <LocationMoveDialog
                locationId={params.id}
                showInMenu={windowIsNarrow}
                dataGridAction
              />,
              <LocationDeleteButton
                locationId={params.id}
                showInMenu={windowIsNarrow}
              />,
            ])
          }
          return [];
        }
      },
      // hidden columns for filtering
      { field: 'name' },
      { field: 'type' },
      { field: 'uuid' },
    ],
    [windowIsNarrow]);

  const getCellClassName = (params) => {
    const isDeviceStr = params.row?.isDeviceRow ? 'deviceRow-' : 'locationRow-';
    switch (params.field) {
      case '__tree_data_group__':
        return isDeviceStr + 'leftmost';

      case 'actions':
        return isDeviceStr + 'rightmost';

      default:
        return params.row?.isDeviceRow ? 'deviceRow-rightmost' : 'locationRow-inner';
    }
  };

  return (
    <DataGridPro
      localeText={useDataGridLocaleText()}
      apiRef={apiRef}
      treeData
      getTreeDataPath={useCallback(row => row.path, [locations])}
      rows={tableRows}
      columns={columns}
      disableColumnMenu
      slots={{ toolbar: LocationTableToolBar }}
      initialState={{
        columns: {
          // hide default detail panel toggles and columns that are only needed for sorting
          columnVisibilityModel: {
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            'name': false,
            'type': false,
            'uuid': false,
          }
        },
        // apply quick filtering (search bar) to hidden columns
        filter: {
          filterModel: {
            items: [],
            quickFilterExcludeHiddenColumns: false,
          },
        },
      }}
      loading={getAlertsIsLoading}
      hideFooter
      density='compact'
      groupingColDef={groupingColDef}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      disableSelectionOnClick={true}
      getCellClassName={getCellClassName}
      getRowSpacing={() => ({ top: 10 })}
      sx={dataGridStyles}
    />
  )
}

const LocationTable = withTranslation()(PureLocationTable)
export {
  LocationTable,
}

